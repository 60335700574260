















































import BaseNewsletterWidget from '../../../../base/components/sidebar/widgets/NewsletterWidget.vue'

export default class NewsletterWidget extends BaseNewsletterWidget {}
